import React, { useEffect, useRef, useState } from "react";

const AppCarousel = ({
  children,
  isInfinite,
  speed,
  withArrows,
  width,
  styleCarousel,
}) => {
  const carouselRef = useRef(null);
  const bandRef = useRef(null);
  const [translateX, setTranslateX] = useState(0);
  const transitionProperties = `${speed || "10s"} linear`;
  const [transition, setTransition] = useState(transitionProperties);

  useEffect(() => {
    if (isInfinite) {
      const bandClone = bandRef.current.firstChild.cloneNode(true);
      const bandWidth = bandRef.current.firstChild.clientWidth;
      bandRef.current.appendChild(bandClone);
      setTranslateX(bandWidth);
    }
  }, [isInfinite]);

  function next() {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: carouselRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  }

  function prev() {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -carouselRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  }

  function handleTransition() {
    setTranslateX(0);
    setTransition("none");
    setTimeout(() => {
      const bandWidth = bandRef.current.firstChild.clientWidth;
      setTranslateX(bandWidth);
      setTransition(transitionProperties);
    });
  }

  return (
    <div className="relative w-full group/carousel">
      {withArrows && (
        <div className="w-0 h-0 opacity-0 group-hover/carousel:opacity-100 with-transition">
          <div className="absolute z-10 top-0 left-0 grid place-items-center w-16 h-full bg-arrow-carousel">
            <button onClick={prev}>
              <i
                className="iconify"
                data-icon="material-symbols:arrow-back-ios-new-rounded"
              />
            </button>
          </div>
          <div className="absolute z-10 top-0 right-0 grid place-items-center h-full w-16 bg-arrow-carousel rotate-180">
            <button onClick={next}>
              <i
                className="iconify"
                data-icon="material-symbols:arrow-back-ios-new-rounded"
              />
            </button>
          </div>
        </div>
      )}
      <div ref={carouselRef} className="app-carousel overflow-auto">
        <div
          ref={bandRef}
          className={`flex w-fit h-full ${width}`}
          style={{
            transform: `translateX(-${translateX}px)`,
            transition: transition,
          }}
          onTransitionEnd={handleTransition}
        >
          <div className={`flex w-full ${styleCarousel}`}>
            {React.Children.map(children, (child, index) => (
              <div key={index} className="flex-grow-0 flex-shrink-0">
                {child}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppCarousel;
