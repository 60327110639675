import React from "react";
import { Link } from "react-router-dom";

import Redes from "../../global/Redes";

const HomeHero = () => {
  return (
    <div
      className="app-hero gradient-y md:gradient-tl"
      style={{
        "--bg-image": `url(${require("../../assets/img/home/bg-home-hero-desktop.jpg")})`,
      }}
    >
      <div className="app-hero__container md:h-auto">
        <h1 className="app-hero__title lg:leading-[70px] mt-32 md:mt-40 md:mb-7 text-start md:max-w-3xl">
          <span className="app-hero__title--small ">forager foundation</span>
          preserving and promoting our shared heritage
        </h1>
      </div>
      <div className="container relative pt-6 md:pt-0 pb-40 md:pb-56">
        <div className="flex flex-col md:flex-row gap-5 mb-32 md:mb-36">
          <Link
            to="/our-story"
            className="app-primary-button py-4 px-14 mx-auto md:mx-0 with-transition"
          >
            about us
          </Link>
          <Link
            to="/contact"
            className="app-secondary-button py-4 px-10 mx-auto md:mx-0 with-transition"
          >
            get involved
          </Link>
        </div>
        <Redes styleIcons="justify-center md:justify-start md:pl-24" />
        <img
          src={require("../../assets/img/home/sheets.jpg")}
          alt="sheets"
          className="w-4/5 md:w-1/4 absolute md:static left-0 bottom-[8.1rem]"
        />
      </div>
    </div>
  );
};

export default HomeHero;
