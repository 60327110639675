import React from "react";
import MainLayout from "../layouts/MainLayout";
import OurStoryHero from "../components/our-story/OurStoryHero";
import OurStoryPrograms from "../components/our-story/OurStoryPrograms";
import OurStoryForagerNetwork from "../components/our-story/OurStoryForagerNetwork";

const OurStory = () => {
  return (
    <MainLayout>
      <OurStoryHero />
      <OurStoryPrograms />
      <OurStoryForagerNetwork />
    </MainLayout>
  );
};

export default OurStory;
