import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./page/Home";
import OurStory from "./page/OurStory";
import MissionsAndValue from "./page/MissionsAndValue";
import HeritageSites from "./page/HeritageSites";
import YaleHistoricSite from "./page/YaleHistoricSite";
import PointElliceHouse from "./page/PointElliceHouse";
import Contact from "./page/Contact";
import Error404 from "./page/Error404";

const App = () => {
  if (!window.location.host.includes("localhost")) {
    if (!window.location.protocol.includes("s")) {
      window.location.replace(
        `https://${window.location.host}${
          window.location.pathname.length > 1
            ? `/${window.location.pathname}`
            : ""
        }`,
      );
    }
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/our-story" element={<OurStory />} exact />
        <Route
          path="/mission-and-values"
          element={<MissionsAndValue />}
          exact
        />
        <Route path="/heritage-sites" element={<HeritageSites />} exact />
        <Route
          path="/yale-historic-site"
          element={<YaleHistoricSite />}
          exact
        />
        <Route
          path="/point-ellice-house-museum"
          element={<PointElliceHouse />}
        />
        <Route path="/contact" element={<Contact />} exact />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
  );
};

export default App;
