import React from "react";
import { Link } from "react-router-dom";

const AppDrawer = () => {
  return (
    <div className="relative container flex md:hidden">
      <Link to="/">
        <img
          src={require("../../assets/logos/Logo-forager-foundation.png")}
          alt="Logo Forager foundation"
          className="z-50 w-40 absolute top-6"
        />
      </Link>
      <label
        htmlFor="drawer-check"
        className="block w-fit p-3 rounded-full fixed right-5 top-6 z-50 bg-primary"
      >
        <i
          className="iconify text-2xl text-white"
          data-icon="ci:hamburger-lg"
        />
      </label>
      <input
        type="checkbox"
        name="drawer-check"
        id="drawer-check"
        className="peer hidden"
      />
      <div className="left-full peer-checked:left-0 flex fixed z-50 h-screen bg-transparent peer-checked:bg-denim/10 w-screen with-transition duration-300">
        <label htmlFor="drawer-check" className="flex-1" />
        <div className="bg-denim w-2/3 h-screen ml-auto flex flex-col">
          <label
            htmlFor="drawer-check"
            className="block w-fit p-3 rounded-full z-50 ml-auto mt-5 mr-5"
          >
            <i className="iconify text-2xl text-white" data-icon="ph:x-bold" />
          </label>
          <ul className="pl-4 px-10 text-white grid gap-7 mt-12">
            <li className="flex flex-wrap justify-between">
              <input
                type="checkbox"
                className="peer hidden drawer-toggle"
                id="peer-about-mobile"
                name="peer-about-mobile"
              />
              <label htmlFor="peer-about-mobile">
                <span className="font-bold">about</span>
              </label>
              <label
                htmlFor="peer-about-mobile"
                className="rotate-90 peer-checked:-rotate-90 block with-transition"
              >
                <i
                  className="iconify font-bold"
                  data-icon="material-symbols:arrow-forward-ios-rounded"
                />
              </label>
              <ul className="hidden peer-checked:flex flex-col w-full gap-3 py-4">
                <li>
                  <Link to="/our-story">Our Story</Link>
                </li>
                <li>
                  <Link to="/mission-and-values">Mission & Values</Link>
                </li>
              </ul>
            </li>
            {/* <li className="flex flex-wrap justify-between">
              <input
                type="checkbox"
                className="peer hidden"
                id="peer-heritage-mobile"
                name="peer-heritage-mobile"
              />
              <label htmlFor="peer-heritage-mobile">
                <span className="font-bold">heritage program</span>
              </label>
              <label
                htmlFor="peer-heritage-mobile"
                className="rotate-0 peer-checked:rotate-90 block"
              >
                <i
                  className="iconify font-bold"
                  data-icon="material-symbols:arrow-forward-ios-rounded"
                />
              </label>
              <ul className="hidden peer-checked:flex flex-col w-full gap-3 py-4">
                <li>Yale Historic Site</li>
                <li>Point Ellice House Museum</li>
              </ul>
            </li> */}
            {/* <Link to="/our-team" className="font-bold">
              our team
            </Link> */}
            <Link to="/heritage-sites" className="font-bold">
              heritage sites
            </Link>
            <Link to="/contact" className="font-bold">
              contact
            </Link>
          </ul>
          <div className="absolute bottom-48">
            <img
              src={require("../../assets/img/home/sheetsonly-green.png")}
              alt=""
              className="mx-auto w-1/2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppDrawer;
