import React from "react";
import useBreakpoints from "../hooks/UseBreakpoints";

import MainLayout from "../layouts/MainLayout";

const PointElliceHouse = () => {
  const { md } = useBreakpoints();

  return (
    <MainLayout>
      <div
        className="app-hero gradient-y md:gradient-tl"
        style={{
          "--bg-image": `url(${require(md
            ? "../assets/img/point/bg-point-ellice-desktop.jpg"
            : "../assets/img/point/bg-point-ellice.png")})`,
        }}
      >
        <div className="app-hero__container pt-1">
          <h1 className="app-hero__title lg:max-w-2xl lg:leading-[70px] pb-5">
            <span className="app-hero__title--small">
              victoria’s historic house museum
            </span>
            point ellice house museum
          </h1>
        </div>
      </div>
      <div className="container lg:px-[252px]">
        <div className="md:text-center">
          <h2 className="font-title text-primary mb-8">
            meeting a true gem in victoria,{" "}
            <span className="uppercase">bc</span>
          </h2>
          <p className="text-white mb-16 lg:mb-20">
            The Point Ellice House & Gardens are a national and provincial
            historic site located in Victoria, British Columbia. Built over two
            years between 1861 and 1862,  Point Ellice House is one of the
            oldest homes in Victoria. It was built for Catherine and Charles
            Wallace and showcases the Italianate Villa-style design of the 19th
            century. This stunning house has stood the test of time, and its
            unique character-defining elements make it a must-visit destination
            for anyone interested in history and architecture.
          </p>
        </div>
      </div>
      <div className="bg-white mt-6">
        <div className="grid lg:grid-cols-2 md:container lg:gap-32 lg:pt-24">
          <div className="container lg:px-0 mb-12 lg:mb-0 lg:order-2">
            <h2 className="font-title text-primary pt-12 pb-8 lg:pt-0">
              the house
            </h2>
            <div className="grid gap-5">
              <p>
                The prime location of Point Ellice offers breathtaking views of
                the Selkirk Water/Gorge Waterway, making it accessible both by
                land and water. The shallow-pitched gabled roofs, wide
                overhanging bracketed eaves, verandas and porches, tall spacious
                windows, and classical detailing add to the charm of this
                single-story (plus attic) rambling Victorian Italianate villa.
                The interior boasts an asymmetrical floor plan and spatial
                configuration, with narrow halls and enclosed rooms, preserving
                the physical integrity of the house.
              </p>
              <p>
                Within the walls of the historic house is one of the largest
                collections of Victorian-era artifacts in Canada. From paintings
                to furniture, books to games, there is a wealth of history
                waiting to be explored by each visitor.
              </p>
            </div>
          </div>
          <div
            className="h-96 lg:h-full w-full bg-cover bg-no-repeat bg-center rounded-tr-3xl rounded-bl-3xl"
            style={{
              backgroundImage: `url(${require("../assets/img/point/the-point.jpg")})`,
            }}
          />
        </div>
        <div className="grid lg:grid-cols-2 md:container lg:gap-32 lg:py-24">
          <div className="container lg:px-0 mb-12 lg:mb-0">
            <h2 className="font-title text-primary pt-12 pb-8 lg:pt-0">
              the gardens
            </h2>
            <div className="grid gap-5">
              <p>
                Point Ellice’s beautiful gardens are rich in history and
                heritage, representing the passion for gardening passed down by
                the O’Reilly family. The Wellingtonia redwood tree,
                Sequoiadendron giganteum, still towers above the west lawn,
                ordered by Peter O’Reilly from San Francisco in the early 1870s.
                The garden area, covering just under two acres, is home to a
                variety of trees, shrubs, and flowers, including the South
                Garden, a "working" garden that provided fresh produce all year
                round.
              </p>
              <p>
                Garden tours and events are held throughout the year, allowing
                visitors to experience the beauty of a bygone era. Don't miss
                the chance to witness the breathtaking views, learn about the
                history and heritage, and immerse yourself in the stunning
                gardens of Point Ellice House. 
              </p>
            </div>
          </div>
          <div
            className="h-80 lg:h-full w-full bg-cover bg-no-repeat bg-center rounded-tl-3xl rounded-br-3xl"
            style={{
              backgroundImage: `url(${require("../assets/img/point/the-garden.jpg")})`,
            }}
          />
        </div>
        <div className="container max-w-6xl md:text-center mx-auto py-12 md:pt-0 md:pb-24 text-denim">
          <img
            src={require("../assets/logos/logo-short-forager-foundation.png")}
            alt="Logo short forager foundation"
            className="w-28 lg:w-20 md:mx-auto mb-5"
          />
          <h2 className="mb-8 font-title text-primary lg:text-5xl font-bold md: text-center">
            forager at point ellice house
          </h2>
          <p className="mb-5">
            The Point Ellice House Museum is part of the Province of British
            Columbia’s heritage portfolio. The Province contracts out the
            management of these sites to different organizations that are
            dedicated to preserving heritage. Forager Foundation was asked by
            the Province to take on temporary management of the Site from May 1,
            2023 to December 31, 2023 while the search for a longer-term manager
            was conducted.
          </p>
          <p className="mb-5">
            By working with many members of the site’s staff, they became proud
            members of the Forager Foundation team. Their work continued along
            the great work that was done over the years as well as putting the
            unique innovative mark that Forager is known for on this heritage
            site. We created engaging online experiences to showcase the House
            and its history, designed publications to share the Site's story
            with different audiences, and developed new programs to bring in
            more of the community.
          </p>
          <p className="mb-5">
            We are also putting together a Community Input Council made up of
            locals to ensure that the work we do is valued by the community and
            that we maintain the heritage value that the community sees from
            this site.
          </p>
          <p className="mb-8">
            If you are a Victoria local and are interested in being part of the
            Community Input Council, contact us to put your name forward.
          </p>
        </div>
      </div>
    </MainLayout>
  );
};

export default PointElliceHouse;
