import React from "react";

const AppLeaves = ({ className, fillColor = "#709E00", color="primary" }) => {
  return (
    <div className={`relative overflow-hidden ${className}`}>
      <div className="ml-3">
        <svg
          width="69"
          height="30"
          viewBox="0 0 69 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.1567 17.8474C10.1567 17.8474 15.7024 13.8264 19.3742 20.0945C23.046 26.3626 24.5029 29.5139 24.5029 29.5139C24.5029 29.5139 20.1787 26.9542 16.2287 25.8673C12.2786 24.7804 11.1159 24.4653 10.4441 20.061C10.0756 18.2153 10.1567 17.8474 10.1567 17.8474Z"
            fill={`${fillColor}`}
          />
          <path
            d="M2.8527 14.5643C2.8527 14.5643 -1.75142 11.2065 2.26758 6.69351C6.28657 2.18053 8.40694 0.219575 8.40694 0.219575C8.40694 0.219575 7.48997 4.29794 7.64261 7.70266C7.79526 11.1074 7.84409 12.1084 4.53079 13.7693C3.16459 14.5341 2.8527 14.5643 2.8527 14.5643Z"
            fill={`${fillColor}`}
          />
          <path
            d="M25.0809 13.7634C25.0809 13.7634 26.6217 12.6231 28.4248 12.5305C30.2279 12.4378 31.6447 11.4515 33.2625 9.46451C34.8803 7.47755 36.4525 5.48919 38.21 4.10231C38.21 4.09803 23.094 1.88302 25.0809 13.7634Z"
            fill={`${fillColor}`}
          />
          <path
            d="M57.4505 13.4956C57.4505 13.4956 58.4921 12.7778 59.711 12.7195C60.9299 12.6612 61.8877 12.0404 62.9814 10.7897C64.075 9.53905 65.1378 8.28751 66.3259 7.41456C66.3259 7.41187 56.1073 6.01767 57.4505 13.4956Z"
            fill={`${fillColor}`}
          />
          <path
            d="M42.2641 16.8734C42.2641 16.8734 46.8052 16.5641 48.9105 21.5779C51.0159 26.5918 51.1433 29.2407 51.1433 29.2407C51.1433 29.2407 50.0718 27.0499 45.9378 25.3045C41.8039 23.5592 39.4189 21.3054 42.2641 16.8734Z"
            fill={`${fillColor}`}
          />
          <path
            d="M61.7242 17.5964C61.7242 17.5964 64.3585 16.7047 66.3651 19.2359C68.3716 21.767 68.8502 23.2594 68.8502 23.2594C68.8502 23.2594 67.8839 22.1772 65.1772 21.8317C62.4705 21.4862 60.719 20.5749 61.7242 17.5964Z"
            fill={`${fillColor}`}
          />
        </svg>
      </div>
      <span
        className={`absolute top-1/2 -translate-y-1/2 w-full rounded-3xl h-[2px] bg-${color}`}
      />
    </div>
  );
};

export default AppLeaves;
