import React from "react";

const OurStoryPrograms = () => {
  return (
    <div className="bg-white">
      <div className="grid lg:grid-cols-2 md:container lg:gap-32 lg:pt-24">
        <div className="container lg:px-0 mb-12 lg:mb-0 lg:order-2">
          <h2 className="font-title text-primary pt-12 pb-8 lg:pt-0">
            original programs
          </h2>
          <div className="grid gap-5">
            <p>
              One of the most popular programs originally developed by Forager
              Foundation was walking tours through local parks exploring wild
              plants and their uses in North America and Europe. These ran in
              parks across Southern BC for several seasons and often included
              lunches created by local Indigenous caterers. Along with these
              tours, two magazine publications were launched: Forager Magazine
              and West Coast Wild Harvest. With a growing number of readers,
              these publications were a promising new program ready for future
              development.
            </p>
            <p>
              So many of the original programs of Forager Foundation were
              focused in Canada but through a partnership with the Tropical
              Conservation Consortium, the Foundation embarked on a new program
              of BioCultural Gardens. The main project that took place was the
              revitalization of an urban garden at the Casa Huichol Indigenous
              Healing Centre. The garden is located in the urban area of
              Zapopan, near Guadalajara, Mexico. The Centre facilitates medical
              and other services for Indigenous Huichol families coming to the
              city from remote mountain communities of the western Sierra Madre.
            </p>
          </div>
        </div>
        <div
          className="h-96 lg:h-full w-full bg-cover bg-no-repeat bg-center rounded-tr-3xl rounded-bl-3xl"
          style={{
            backgroundImage: `url(${require("../../assets/img/our-story/mountains.png")})`,
          }}
        />
      </div>
      <div className="grid lg:grid-cols-2 md:container lg:gap-32 lg:py-24">
        <div className="container lg:px-0 mb-12 lg:mb-0">
          <h2 className="font-title text-primary pt-12 pb-8 lg:pt-0">
            the hiatus 
          </h2>
          <div className="grid gap-5">
            <p>
              As a nonprofit made up of young professionals, life began to take
              the original team in different directions. As a volunteer-run
              organization, it was understandable that new opportunities would
              need to be explored and with that, the original board decided to
              put the Foundation on hold in the hopes that one day it would be
              able to start again.
            </p>
            <p>
              From 2017 until 2023, Forager Foundation lie dormant, waiting for
              the perfect opportunity to resurrect itself and continue working
              towards its original mandate to preserve and promote natural and
              cultural heritage. During that time, one of the founders, Bryce
              Mathew Watts, went on to develop the business arm of Forager.
              These businesses directly support the reinvigorated work that
              Forager Foundation does and through their specializations in
              support services focussed on arts, culture, heritage, and tourism
              organizations they build a strong foundation for Forager
              Foundation to be able to implement the innovative programs it is
              developing.
            </p>
          </div>
        </div>
        <div
          className="h-80 lg:h-full w-full bg-cover bg-no-repeat bg-center rounded-tl-3xl rounded-br-3xl"
          style={{
            backgroundImage: `url(${require("../../assets/img/our-story/walking-people.png")})`,
          }}
        />
      </div>
    </div>
  );
};

export default OurStoryPrograms;
