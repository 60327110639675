import React from "react";
import { Link } from "react-router-dom";
import useBreakpoints from "../../hooks/UseBreakpoints";

const HomeHeritageProgram = () => {
  const { md } = useBreakpoints();

  return (
    <>
      <div className="container mb-24">
        <h2 className="font-title text-center text-primary -tracking-[0.03em] mb-8">
          heritage sites program
        </h2>
        <p className="text-center text-white mb-12 md:w-3/4 md:mx-auto">
          One of our main programs is to support the preservation of natural and
          cultural heritage by managing unique sites and institutions looking
          for a new perspective to achieve their mandates. Through government
          tendering and strategic mergers, Forager Foundation is building a
          network of heritage sites that collaborate closely to support one
          another and share valuable resources that are not always as readily
          available individual sites.
        </p>
        <div className="mx-auto w-full">
          <Link
            to="/heritage-sites"
            className="app-secondary-button mb-16 with-transition"
          >
            explore the program
          </Link>
        </div>
        <div className="grid gap-5 md:gap-8 md:grid-cols-2">
          <Link to="/yale-historic-site">
            <div
              className={`group/Cards h-full relative block rounded-br-3xl rounded-tl-3xl overflow-hidden border-4 border-transparent hover:border-primary with-transition`}
            >
              <div
                className="h-56 md:h-80 w-full bg-cover bg-no-repeat bg-center group-hover/Cards:scale-105 with-transition"
                style={{
                  backgroundImage: `url(${require("../../assets/img/home/hut.jpg")})`,
                }}
              />
              <div className="relative py-2 px-6 bg-white">
                <h5 className="text-2xl text-primary font-bold py-1 w-10/12">
                  yale historic site
                </h5>
                <span
                  className={`absolute -top-2 right-3.5 -translate-y-5 w-14 aspect-square grid place-items-center rounded-full bg-denim group-hover/Cards:bg-primary ring-4 ring-white group-hover/Cards:scale-110 with-transition`}
                >
                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25 1.5L8.75 9L1.25 16.5"
                      className="stroke-white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </Link>
          <Link to="/point-ellice-house-museum">
            <div
              className={`group/Cards h-full relative block rounded-bl-3xl rounded-tr-3xl overflow-hidden border-4 border-transparent hover:border-primary with-transition`}
            >
              <div
                className="h-56 md:h-80 w-full bg-cover bg-no-repeat bg-center group-hover/Cards:scale-105 with-transition"
                style={{
                  backgroundImage: `url(${require("../../assets/img/home/point-ellice-house-museum.jpg")})`,
                }}
              />
              <div className="relative py-2 px-6 bg-white">
                <h5 className="text-2xl text-primary font-bold py-1 w-10/12">
                  point ellice house museum
                </h5>
                <span
                  className={`absolute -top-2 right-3.5 -translate-y-5 w-14 aspect-square grid place-items-center rounded-full bg-denim group-hover/Cards:bg-primary ring-4 ring-white group-hover/Cards:scale-110 with-transition`}
                >
                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25 1.5L8.75 9L1.25 16.5"
                      className="stroke-white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div
        className="app-hero gradient-b"
        style={{
          "--bg-image": `url(${require(md
            ? "../../assets/img/home/islands-desktop.jpg"
            : "../../assets/img/home/islands.jpg")})`,
        }}
      >
        <div className="app-hero__container pt-24 pb-32 md:py-32">
          <h1 className="app-hero__title lg:max-w-2xl lg:leading-[70px] font-title text-primary mx-auto">
            <p className="lowercase block text-2xl text-white font-normal text-center">
              <span className="capitalize">we </span> are a collaborative
              community
            </p>
            get involved today
            <div className="mx-auto w-full">
              <Link
                to="/contact"
                className="app-secondary-button py-4 px-10 mx-auto mt-8 text-base with-transition"
              >
                become a member
              </Link>
            </div>
          </h1>
        </div>
      </div>
    </>
  );
};

export default HomeHeritageProgram;
