import React from "react";
import { Link } from "react-router-dom";
import useBreakpoints from "../../hooks/UseBreakpoints";

const OurStoryHero = () => {
  const { md } = useBreakpoints();

  return (
    <section className="relative pb-20 lg:pb-24">
      <div
        className="app-hero gradient-y md:gradient-tl"
        style={{
          "--bg-image": `url(${require(md
            ? "../../assets/img/our-story/our-story-hero.png"
            : "../../assets/img//our-story/our-story-hero-mobile.png")})`,
        }}
      >
        <div className="app-hero__container pt-10">
          <h1 className="app-hero__title lg:max-w-5xl lg:mt-28">
            <span className="app-hero__title--small">the story until now</span>
            about forager foundation
          </h1>
        </div>
      </div>
      <div className="container lg:px-[252px]">
        <div className="text-center">
          <h2 className="font-title text-primary mb-8 mt-12">
            how it all began
          </h2>
          <p className="text-white mb-16 lg:mb-20">
            Forager Foundation was founded back in 2013 by a group of friends
            out of the University of British Columbia. Shortly after graduating,
            they all came together with a passion for natural and cultural
            heritage and decided to form Forager Foundation as an outlet for
            their passion. In the first years, they worked together to develop
            engaging public programming and attended conferences to further
            their development in heritage.
          </p>
        </div>
      </div>
      <div className="text-center max-w-5xl mx-auto px-4 mb-8 text-white pt-20">
        <img
          src={require("../../assets/logos/logo-short-forager-foundation.png")}
          alt="Logo short forager foundation"
          className="w-28 lg:w-20 mx-auto mb-5"
        />
        <h2 className="mb-8 font-title lg:text-5xl font-bold">
          the new forager foundation
        </h2>
        <p className="mb-5">
          Now, with new board members and a growing team, Forager Foundation is
          back and ready to get back to work. With the development of our first
          new program, the Foundation is taking its experience in heritage one
          step further by taking over the operations of heritage sites and
          managing them directly. Through open RFPs and other strategic
          partnerships, Forager Foundation’s Heritage Sites Program is working
          towards its mandate by ensuring that vital sites continue to innovate
          and develop public engagement that drives operational excellence and
          sustainability. As capacity builds within the Foundation, original
          programming will be revived and new innovative programs will continue
          to be developed.
        </p>
        <p>
          If you want to join us on our exciting journey, join us! Collaboration
          is key to our success and we want to build a community of volunteers
          that share our passion for natural and cultural heritage.
        </p>
      </div>
      <div className="mx-auto">
        <Link
          to="/contact"
          className="break-keep app-primary-button py-4 w-52 px-9 mx-auto with-transition"
        >
          get involved
        </Link>
      </div>
      <div
        id="our-story-forest"
        className="absolute -z-10 bottom-0 w-full h-80 lg:h-[646px]"
      />
    </section>
  );
};

export default OurStoryHero;
