import React from "react";
import { Link } from "react-router-dom";
import useBreakpoints from "../hooks/UseBreakpoints";

import MainLayout from "../layouts/MainLayout";

const HeritageSites = () => {
  const { md } = useBreakpoints();
  return (
    <MainLayout>
      <div
        className="app-hero gradient-y md:gradient-tl"
        style={{
          "--bg-image": `url(${require(md
            ? "../assets/img/heritage-sites/bg-heritage-sites.jpg"
            : "../assets/img/heritage-sites/bg-heritage-sites-mobile.jpg")})`,
        }}
      >
        <div className="app-hero__container pt-1">
          <h1 className="app-hero__title lg:max-w-5xl lg:leading-[70px] pb-5">
            <span className="app-hero__title--small">
              Working To Preserve Important Sites
            </span>
            heritage sites program
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="text-center lg:w-8/12 mx-auto">
          <h2 className="mt-7 font-title text-primary mb-8">
            a speciality in supporting heritage
          </h2>
          <p className="text-white mb-16 lg:mb-28">
            Forager Foundation’s speciality in heritage added with the support
            given by the Forager International business divisions, meant it was
            a natural next step for the Foundation to explore opportunities to
            take over the management of heritage sites as a way to protect them
            from closure and to increase public awareness and appreciation for
            the unique stories they hold.
          </p>
        </div>
      </div>
      <div className="bg-white mt-6">
        <div className="grid lg:grid-cols-2 md:container lg:gap-32 lg:pt-24">
          <div className="container lg:px-0 mb-12 lg:mb-0 lg:order-2">
            <h2 className="font-title text-primary pt-12 pb-8 lg:pt-0">
              government tenders 
            </h2>
            <div className="grid gap-5">
              <p>
                One way that this program is growing is by applying for
                government contracts. These are open application processes
                whereby governments, in the case of our first sites the Province
                of British Columbia, own heritage properties that they contract
                out the operations of to nonprofits with direct specializations
                in heritage. These applications require a detailed and well
                thought out operations plan to showcase how the applicant will
                build off of the current Site Manager’s work and continue
                showcasing the unique heritage story of the Site.
              </p>
              <p>
                In the case of Forager Foundation, our team’s experience in
                heritage interpretation, the connection to the
                internationally-recognized Forager International Group of
                Companies, a direct connection to award winning programs, as
                well as the participation in the Forager Partnership Network, is
                why governments recognize the strength in our applications. The
                Forager Partnership Program already incorporates a significant
                number of heritage institutions across Western Canada allowing
                for collaboration in a number of areas well beyond what
                individual institutions can with limited capacity.
              </p>
            </div>
          </div>
          <div
            className="h-80 lg:h-full w-full bg-cover bg-no-repeat bg-center rounded-tr-3xl rounded-bl-3xl"
            style={{
              backgroundImage: `url(${require("../assets/img/heritage-sites/government-tenders .jpg")})`,
            }}
          />
        </div>
        <div className="grid lg:grid-cols-2 md:container lg:gap-32 lg:py-24">
          <div className="container lg:px-0 mb-12 lg:mb-0">
            <h2 className="font-title text-primary pt-12 pb-8 lg:pt-0">
              organizational mergers
            </h2>
            <div className="grid gap-5">
              <p>
                Repeated studies during the COVID-19 pandemic showed that a
                significant number of heritage institutions faced closure or a
                long drawn-out recovery period due to dropping revenue, visitor
                numbers, and school programs. With several heritage
                organizations closing their doors at the beginning of 2023
                already, Forager Foundation is working to find ways to support
                more organizations that are facing tough times. Instead of
                seeing more important sites close, Forager Foundation is
                developing ways to merge with these organizations and
                incorporate their operations into the larger Heritage Site
                Program in order to share the Foundation’s resources between new
                sites and to develop more ways to sustainably keep the doors
                open at heritage sites whereven possible.
              </p>
            </div>
          </div>
          <div
            className="h-80 lg:h-full w-full bg-cover bg-no-repeat bg-center rounded-tl-3xl rounded-br-3xl"
            style={{
              backgroundImage: `url(${require("../assets/img/heritage-sites/team.jpg")})`,
            }}
          />
        </div>
        <div className="lg:container bg-white pb-1 lg:pb-12">
          <div className="container lg:px-0 mb-12">
            <h2 className="font-title text-primary text-center pt-12 pb-5 lg:pb-8 lg:pt-0">
              current sites
            </h2>
            <div className="grid gap-5">
              <Link to="/yale-historic-site">
                <div className="bg-denim rounded-br-3xl rounded-tl-3xl grid lg:grid-cols-2">
                  <div
                    className="h-80 lg:h-full w-full lg:w-[80%] bg-cover bg-no-repeat bg-center rounded-br-3xl rounded-tl-2xl"
                    style={{
                      backgroundImage: `url(${require("../assets/img/heritage-sites/img-yale.jpg")})`,
                    }}
                  />
                  <div className="px-8 lg:px-0 mt-8 lg:mt-11 lg:-ml-12">
                    <h2 className="font-title text-primary mb-8">
                      yale historic site
                    </h2>
                    <p className="text-white mb-11 lg:pr-14">
                      Yale is a diverse site with a long history. Originally an
                      ancient village of the Yale First Nation dating back
                      thousands of years and then a 19th-century trading post of
                      the Hudson’s Bay Company, Yale as it stands today is a
                      collection of heritage buildings that share the unique
                      story of the area and the Gold Rush that drove this
                      settlement along the Fraser River into the largest city
                      north of San Francisco by the mid-1800s.
                    </p>
                    <div className="flex gap-1 hover:gap-3 text-white hover:text-primary with-transition items-center pb-14">
                      <span className="font-bold">Museum Profile</span>
                      <i
                        className="iconify"
                        data-icon="material-symbols:arrow-forward-ios-rounded"
                      />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="container lg:px-0 mb-12">
            <h2 className="font-title text-primary text-center pt-12 pb-5 lg:pb-8 lg:pt-0">
              past sites
            </h2>
            <div className="grid gap-5">
              <Link to="/point-ellice-house-museum">
                <div className="bg-denim rounded-br-3xl rounded-tl-3xl grid lg:grid-cols-2">
                  <div
                    className="h-80 lg:h-full w-full lg:w-[80%] bg-cover bg-no-repeat bg-center rounded-br-3xl rounded-tl-2xl"
                    style={{
                      backgroundImage: `url(${require("../assets/img/heritage-sites/poil-ellice.jpg")})`,
                    }}
                  />
                  <div className="px-8 lg:px-0 mt-8 lg:mt-11 lg:-ml-12">
                    <h2 className="font-title text-primary mb-8">
                      point ellice house museum
                    </h2>
                    <p className="text-white mb-11 lg:pr-14">
                      Situated along the Selkirk Water & Gorge Waterway in
                      Victoria, Canada, the Point Ellice House Museum is a
                      hidden gem in British Columbia’s capital. With one of the
                      largest collections of Victorian-era artifacts housed in
                      one of the City’s oldest houses, the museum is a space
                      ready to engage with both the good an d bad that comes
                      from Canada’s 19th-century story and the interactions
                      between government and Indigenous communities.
                    </p>
                    <div className="flex gap-1 hover:gap-3 text-white hover:text-primary with-transition items-center pb-14">
                      <span className="font-bold">Museum Profile</span>
                      <i
                        className="iconify"
                        data-icon="material-symbols:arrow-forward-ios-rounded"
                      />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default HeritageSites;
