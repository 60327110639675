import React from "react";

const HomeAbout = () => {
  const aboutUs = [
    {
      about: "our story",
      title: "the path that led us here",
      description:
        "Founded in 2013, Forager Foundation was launched to preserve and promote natural and cultural heritage. In our first years, we collaborated on BioCultural Garden projects in Latin America, delivered wild plant tours across BC, and published a digital magazine to share the unique stories we came across worldwide. After a few years dormant, Forager Foundation is back and stronger than ever. We are developing new and exciting projects and working directly with governments to achieve our mandate while supporting natural and cultural heritage innovation.",
      img: require("../../assets/img/home/path-through-nature.jpg"),
      styleImg: "md:ml-auto rounded-tl-3xl rounded-br-3xl",
      sheet: (
        <img
          src={require("../../assets/img/home/sheetsonly.png")}
          alt=""
          className="absolute w-1/4 -top-12 left-9 xl:-top-28 xl:left-1 2xl:left-0"
        />
      ),
    },
    {
      about: "our ethos",
      title: "the force behind our work",
      description:
        "Our slogan is “preserving and promoting our shared heritage,” That isn’t just what drives our programs. It guides every aspect of our organization’s operations. We value diversity and multiculturalism above all. As the anthropologist Wade Davis once said, “The world in which you were born is just one model of reality. Other cultures are not failed attempts at being you; they are unique manifestations of the human spirit.” With our team, community of partners, and membership network representing this myriad of backgrounds, we have the best chance of achieving our goals.",
      img: require("../../assets/img/home/sunset-with-friends.jpg"),
      styleImg: "md:mr-auto rounded-tr-3xl rounded-bl-3xl",
      class: "md:order-2",
      sheet: (
        <img
          src={require("../../assets/img/home/sheetsonly.png")}
          alt=""
          className="absolute w-1/4 -top-12 left-9 xl:-top-28 xl:left-1 2xl:left-0"
        />
      ),
    },
    {
      about: "our team",
      title: "the people that guide us",
      description:
        "Forager Foundation appreciates the responsibility placed upon us by our heritage sector partners and the trust that governments and private entities place in us to deliver innovative and engaging heritage programming. Our Board and Staff are passionate about natural and cultural heritage. They are driven professionals with years of experience in their respective areas, and they are the reason Forager Foundation can do the work it does. We strive for a diverse range of backgrounds and perspectives to build a strong foundation for our programs and public engagement. ",
      img: require("../../assets/img/home/faro.jpg"),
      styleImg: "md:ml-auto rounded-tl-3xl rounded-br-3xl",
      sheet: (
        <img
          src={require("../../assets/img/home/sheetsonly.png")}
          alt=""
          className="absolute w-1/4 -top-12 left-9 xl:-top-28 xl:left-1 2xl:left-0"
        />
      ),
    },
  ];
  return (
    <div>
      <div className="mt-28 md:my-28 grid gap-24 text-white">
        {aboutUs.map((e, i) => (
          <div
            key={i}
            className={`grid md:grid-cols-2 md:gap-1 mb-10 md:container place-items-center`}
          >
            <div className={`relative pl-24 md:pr-0 container ${e.class}`}>
              <h3 className="text-2xl">{e.about}</h3>
              <h2 className="text-primary font-title mb-8">{e.title}</h2>
              <p className="mb-14">{e.description}</p>
              {e.sheet}
            </div>
            <img
              src={e.img}
              alt=""
              className={`md:max-w-lg md:col-span-1 ${e.styleImg}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeAbout;
