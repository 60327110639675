import React, { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "../store";

import { createContactMail } from "../actions/contactMailActions";
import {
  CONTACTMAIL_CREATE_FAIL,
  CONTACTMAIL_CREATE_RESET,
} from "../constants/contactMailConstants";

import MainLayout from "../layouts/MainLayout";
import heroBgImgMobile from "../assets/img/contact/forager-fundation-get-intouch-mobile.png";
import heroBgImgDesktop from "../assets/img/contact/forager-fundation-get-intouch.png";
import useBreakpoints from "../hooks/UseBreakpoints";

const Contact = () => {
  const { md } = useBreakpoints();

  const dispatch = useDispatch();
  const contactMailCreate = useSelector((state) => state.contactMailCreate);
  const { error, success } = contactMailCreate;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const subjectHandler = (e) => {
    setSubject(e.target.value);
  };

  const messageHandler = (e) => {
    setMessage(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      name.length > 0 &&
      email.length > 0 &&
      subject.length > 0 &&
      message.length > 0
    ) {
      const contactMail = {
        name,
        email,
        subject,
        message,
      };

      dispatch(createContactMail(contactMail));
    } else {
      dispatch({
        type: CONTACTMAIL_CREATE_FAIL,
        payload: "Fields with an * are required!",
      });
    }
  };

  useEffect(() => {
    if (success) {
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
      alert("Email sent successfully! Thank you for your interest!");

      setTimeout(() => {
        dispatch({ type: CONTACTMAIL_CREATE_RESET });
      }, 3000);
    }
  }, [success, dispatch]);

  useEffect(() => {
    if (error) {
      alert("Something went wrong :( Please try again!");

      setTimeout(() => {
        dispatch({ type: CONTACTMAIL_CREATE_RESET });
      }, 3000);
    }
  }, [error, dispatch]);

  return (
    <Provider store={store}>
      <MainLayout>
        <div
          className="app-hero gradient-t md:gradient-tl"
          style={{
            "--bg-image": `url(${md ? heroBgImgDesktop : heroBgImgMobile})`,
          }}
        >
          <div className="app-hero__container">
            <h1 className="app-hero__title">
              <span className="app-hero__title--small">
                collaboration is vital to our mission
              </span>
              get in touch
            </h1>
          </div>
        </div>
        <div className="bg-white pt-9 md:pt-16">
          <div className="container grid lg:grid-cols-2 gap-14">
            <section>
              <h2 className="font-title mb-7 text-primary">let’s connect</h2>
              <p className="mb-5 lg:leading-5">
                We are dedicated to building and strengthening connections
                within our community. Our goal is to empower individuals and
                organizations through our services and programs, and we are
                always seeking new ways to engage with those we serve. We would
                be thrilled to hear from you if you want to learn more about our
                work, become a volunteer, or partner with us to make a
                difference in your community. Our team is here to answer any
                questions and help you explore opportunities to get involved.
                Together we can positively impact and preserve our shared
                natural and cultural heritage.
              </p>
              <p className="mb-5">
                <strong className="block">Phone:</strong>
                +1 (250) 800-1831
              </p>
              <p>
                <strong className="block">General Enquiries:</strong>
                info@foragerfoundation.com
              </p>
              <img
                src={require("../assets/img/contact/people.png")}
                alt=""
                className="hidden lg:block mt-7 mb-24"
              />
            </section>
            <section>
              <h3 className="font-title mb-7 text-primary">get in touch</h3>
              <span>
                Fields marked with an <span className="text-primary">*</span>{" "}
                are required
              </span>
              <form className="grid gap-6 mt-5 pb-10" onSubmit={submitHandler}>
                <label htmlFor="name" className="grid gap-1">
                  <span className="font-bold">
                    Name <span className="text-primary">*</span>
                  </span>
                  <input
                    id="name"
                    type="text"
                    className="border border-secondary rounded-md py-1 px-2"
                    value={name}
                    required
                    onChange={nameHandler}
                  />
                </label>
                <label htmlFor="email" className="grid gap-1">
                  <span className="font-bold">
                    Email Address <span className="text-primary">*</span>
                  </span>
                  <input
                    id="email"
                    type="text"
                    className="border border-secondary rounded-md py-1 px-2"
                    value={email}
                    onChange={emailHandler}
                    required
                  />
                </label>
                <label htmlFor="subject" className="grid gap-1">
                  <span className="font-bold">
                    Subject <span className="text-primary">*</span>
                  </span>
                  <input
                    id="subject"
                    type="text"
                    className="border border-secondary rounded-md py-1 px-2"
                    value={subject}
                    onChange={subjectHandler}
                    required
                  />
                </label>
                <label htmlFor="message" className="grid gap-1">
                  <span className="font-bold">
                    Message <span className="text-primary">*</span>
                  </span>
                  <textarea
                    id="message"
                    rows="8"
                    className="border border-secondary rounded-md py-1 px-2"
                    value={message}
                    onChange={messageHandler}
                    required
                  />
                </label>
                <button
                  type="button"
                  className="app-primary-button w-full py-4 lg:w-[271px] with-transition"
                  onClick={submitHandler}
                >
                  submit
                </button>
              </form>
            </section>
          </div>
        </div>
      </MainLayout>
    </Provider>
  );
};

export default Contact;
