import React from "react";
import { Link } from "react-router-dom";

import AppLeaves from "../../global/AppLeaves";

const ForagersCompany = ({ company }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 justify-center gap-y-10 px-6 lg:px-24 pt-14 pb-3">
      <div>
        <div
          className={`grid place-items-center w-full md:w-[23rem] h-72 md:h-[21rem] mb-5 bg-cover bg-no-repeat bg-center ${company.bgStyle}`}
          style={{ backgroundImage: `url(${company.backgroundImage})` }}
        >
          <div className="w-48">{company.logo}</div>
        </div>
        <Link
          target="_blank"
          to={company.link}
          className="font-bold text-secondary hover:text-primary w-fit h-fit with-transition"
        >
          <div className="flex gap-1 hover:gap-3 hover:text-primary lg:pb-20 items-center w-fit h-fit with-transition">
            <span>Visit Website</span>
            <i
              className="iconify mt-1"
              data-icon="material-symbols:arrow-forward-ios-rounded"
            />
          </div>
        </Link>
      </div>
      <div>
        <h4 className="mb-3 text-2xl text-primary font-bold">{company.name}</h4>
        <AppLeaves className="w-24 mb-6" />
        <div className="pb-14">
          <span>{company.description}</span>
        </div>
      </div>
    </div>
  );
};
export default ForagersCompany;
