import React from "react";
import useBreakpoints from "../hooks/UseBreakpoints";

import MainLayout from "../layouts/MainLayout";
import AppLeaves from "../global/AppLeaves";

const MissionsAndValue = () => {
  const { md } = useBreakpoints();
  const cards = [
    {
      title: "preserve heritage:",
      text: "Use programs to safeguard our shared heritage.",
      icon: require("../assets/img/mission-and-values/icon-museum.png"),
      styleCard: "rounded-tl-3xl rounded-br-3xl xl:ml-28",
      background: require("../assets/img/mission-and-values/bg-cards-green.jpg"),
    },
    {
      title: "embrace diversity",
      text: "Give space for groups historically left out.",
      icon: require("../assets/img/mission-and-values/icon-team.png"),
      styleCard: "rounded-tr-3xl rounded-bl-3xl xl:mr-28",
      background: require("../assets/img/mission-and-values/bg-cards-denim.jpg"),
    },
    {
      title: "be innovative",
      text: "Always look for ways to make it better.",
      icon: require("../assets/img/mission-and-values/icon-bulb.png"),
      styleCard: "rounded-tl-3xl rounded-br-3xl",
      background: require("../assets/img/mission-and-values/bg-cards-green.jpg"),
    },
    {
      title: "develop collaboration",
      text: "Work with communities to develop together.",
      icon: require("../assets/img/mission-and-values/icon-puzzle.png"),
      styleCard: "rounded-tr-3xl rounded-bl-3xl",
      background: require("../assets/img/mission-and-values/bg-cards-denim.jpg"),
    },
    {
      title: "be sensitive",
      text: "Don’t forget the impact of past injustices.",
      icon: require("../assets/img/mission-and-values/icon-heart.png"),
      styleCard: "rounded-tl-3xl rounded-br-3xl",
      background: require("../assets/img/mission-and-values/bg-cards-green.jpg"),
    },
  ];
  return (
    <MainLayout>
      <div
        className="app-hero gradient-y md:gradient-tl"
        style={{
          "--bg-image": `url(${require(md
            ? "../assets/img/mission-and-values/bg-mission-and-values-desktop.jpg"
            : "../assets/img/mission-and-values/bg-mission-and-values-mobile.jpg")})`,
        }}
      >
        <div className="app-hero__container pt-20">
          <h1 className="app-hero__title lg:max-w-2xl lg:leading-[70px]">
            <span className="app-hero__title--small">
              see what drives our passion
            </span>
            forager foundation’s mission and values
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="bg-primary lg:w-[70%] mx-auto rounded-tl-2xl mt-10 mb-16 rounded-br-2xl font-bold px-8 lg:px-24 py-8">
          <div className="w-24 mx-auto">
            <AppLeaves fillColor="white" color="white" />
          </div>
          <p className="text-white my-6">
            “Change will not come if we wait for some other person or some other
            time. We are the ones we’ve been waiting for. We are the change that
            we seek.”
          </p>
          <ul className="list-disc w-60 flex ml-auto">
            <li className="break-keep text-lg text-denim">
              President Barack Obama
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-white mt-6">
        <div className="grid lg:grid-cols-2 lg:container lg:gap-32 lg:pt-24">
          <div className="container lg:px-0 mb-12 lg:order-2">
            <h2 className="font-title text-primary pt-12 pb-8 lg:pt-0">
              our mission
            </h2>
            <div className="grid gap-5">
              <p>
                “To preserve and promote the unique natural and cultural
                heritage that has shaped our communities, and to innovate the
                way organizations working in this field engage with the public
                and further their mandates.”
              </p>
              <p>
                What this means is that we actively develop programs that engage
                with communities and develop a deep sense of appreciation for
                the natural and cultural world. Through our growing heritage
                portfolio and other programs, Forager Foundation is building a
                community that works together on the preservation and promotion
                of heritage.
              </p>
            </div>
          </div>
          <div
            className="h-72 md:h-96 w-full bg-cover bg-no-repeat bg-center rounded-tr-3xl rounded-bl-3xl"
            style={{
              backgroundImage: `url(${require("../assets/img/mission-and-values/sky.jpg")})`,
            }}
          />
        </div>
        <div className="grid lg:grid-cols-2 lg:container lg:gap-32 lg:py-24 ">
          <div className="container lg:px-0 mb-12">
            <h2 className="font-title text-primary pt-12 pb-8 lg:pt-0">
              our vision
            </h2>
            <div className="grid gap-5">
              <p>
                “We seek to build a heritage sector that is valued by the
                community and one that creates resources and experiences that
                inspire more people to engage with natural and cultural
                heritage.”
              </p>
              <p>
                What this means is that we are always looking for ways to bring
                the community into heritage spaces and make them active
                participants in heritage. Whether that is developing events in
                local parks, creating interactive programs within a museum
                space, or designing resources that bring natural and cultural
                heritage to life in new and exciting ways, engagement with
                heritage is important to its preservation.
              </p>
            </div>
          </div>
          <div
            className="h-72 md:h-96 w-full bg-cover bg-no-repeat bg-center rounded-tl-3xl rounded-br-3xl"
            style={{
              backgroundImage: `url(${require("../assets/img/mission-and-values/landscape.jpg")})`,
            }}
          />
        </div>
        <h2 className="text-primary font-title mt-12 lg:mt-0 mb-8 text-center">
          our values
        </h2>
        <section className="container flex flex-row flex-wrap justify-center gap-4 pb-12 lg:pb-24">
          {cards.map((card) => (
            <div
              className={`flex w-full md:w-[373px] items-center py-8 pl-7 gap-7 bg-cover ${card.styleCard}`}
              key={card.title}
              style={{ backgroundImage: `url(${card.background})` }}
            >
              <img src={card.icon} alt="" className="w-11 ml-auto" />
              <div className="text-white text-xl">
                <h4 className="mb-5 text-2xl font-bold">{card.title}</h4>
                <p className="text-base pr-2 lg:pr-14">{card.text}</p>
              </div>
            </div>
          ))}
        </section>
      </div>
    </MainLayout>
  );
};

export default MissionsAndValue;
