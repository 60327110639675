import React from "react";

function Redes({ styleIcons }) {
  const socialLinks = [
    {
      href: "https://www.facebook.com/TheForagerFDN",
      icon: <i className="iconify" data-icon="uil:facebook-f" />,
    },
    {
      href: "https://www.twitter.com/theforagerfdn",
      icon: <i className="iconify" data-icon="fe:twitter" />,
    },
    {
      href: "https://instagram.com/theforagerfdn",
      icon: <i className="iconify" data-icon="uil:instagram" />,
    },
    {
      href: "https://www.tiktok.com/@theforagerfdn",
      icon: <i className="iconify" data-icon="ic:baseline-tiktok" />,
    },
    {
      href: "https://www.linkedin.com/company/forager-foundation",
      icon: <i className="iconify" data-icon="mdi:linkedin" />,
    },
  ];
  
  return (
    <div className={`flex gap-2 text-2xl ${styleIcons}`}>
      {socialLinks.map((ele) => (
        <a
          key={ele.href}
          href={ele.href}
          target="_blank"
          rel="noreferrer"
          className="text-white hover:text-primary with-transition"
        >
          {ele.icon}
        </a>
      ))}
    </div>
  );
}

export default Redes;
