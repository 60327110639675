import React from "react";
import bordeIzq from "../assets/img/error-404/borde-izq.png";
import bordeDer from "../assets/img/error-404/borde-der.png";
import BordeDesktopIzq from "../assets/img/error-404/borde-desktop-izq.png";
import BordeDesktopDer from "../assets/img/error-404/borde-desktop-der.svg";
import { Link } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";

const Error404 = () => {
  return (
    <MainLayout noFooter>
      <div className="relative bg-cover home-hero-background h-screen text-white text-center">
        <img
          src={bordeIzq}
          alt=""
          className="block lg:hidden absolute h-[84%] top-[6.7rem] pt-0.5 left-0"
        />
        <img
          src={bordeDer}
          alt=""
          className="block lg:hidden absolute h-3/4 top-24 right-0"
        />
        <img
          src={BordeDesktopIzq}
          alt=""
          className="hidden lg:block absolute h-[80%] top-[7rem] left-5"
        />
        <img
          src={BordeDesktopDer}
          alt=""
          className="hidden lg:block absolute h-3/4 top-[5.8rem] right-5"
        />
        <div className="pt-56 lg:pt-72 container w-full mx-auto">
          <h1 className="text-5xl font-bold pb-5">page not found</h1>
          <p>
            It looks like this page has moved somewhere else. come back to the
            homepage
          </p>
          <Link to="/" className="app-primary-button w-fit py-4 px-10 mx-auto mt-7 with-transition">
            go home
          </Link>
        </div>
      </div>
    </MainLayout>
  );
};

export default Error404;
