import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  contactMailCreateReducer,
  contactMailListReducer,
  contactMailDeleteReducer,
  contactMailAuthReducer,
} from "./reducers/contactMailReducers";

//
//
//
const reducer = combineReducers({
  contactMailCreate: contactMailCreateReducer,
  contactMailList: contactMailListReducer,
  contactMailDelete: contactMailDeleteReducer,
  contactMailAuth: contactMailAuthReducer,
});

const initialState = {};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
