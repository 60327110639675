import React, { useEffect, useId, useRef, useState } from "react";

export default function AppModal({ children, activator, isOpen, boxClass }) {
  const modalId = useId();
  const modal = useRef(null)
  const[canOpen, setCanOpen] = useState(false);

  useEffect(() => {
    document.querySelector('#root').appendChild(modal.current)
  })

  function onModalToggle (ev) {
    setCanOpen(ev.target.checked)
  }

  return (
    <>
      <label htmlFor={modalId}>{activator}</label>
      <input type="checkbox" id={modalId} className="modal-toggle" onInput={onModalToggle} />
      <div ref={modal} className={`modal bg-black/60 ${canOpen ? "modal-open" : ""}`}>
        <div className={`bg-white modal-box relative p-0 ${boxClass}`}>
          <label
            htmlFor={modalId}
            className="z-50 sticky top-10 md:top-2 left-[89%] md:left-[96%] w-10 h-10 grid place-items-center bg-navy-blue bg-white rounded-full text-white cursor-pointer"
          >
           <i className="iconify text-secondary text-xl" data-icon="ph:x-bold" />
          </label>
          {children}
        </div>
      </div>
    </>
  );
}