import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import AppHeader from "./components/AppHeader";
import AppDrawer from "./components/AppDrawer";
import AppFooter from "./components/AppFooter";

const MainLayout = ({ children, noFooter }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 1);
  }, [pathname]);
  return (
    <div className="relative">
      <header className="fixed top-0 z-10 w-full">
        <AppHeader />
      </header>
      <AppDrawer />
      <main>{children}</main>
      {!noFooter ? (
        <footer>
          <AppFooter />
        </footer>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MainLayout;
