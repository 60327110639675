import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/dist";
import useBreakpoints from "../../hooks/UseBreakpoints";

const AppHeader = () => {
  const { md } = useBreakpoints();
  const [hasScroll, setHasScroll] = useState(false);
  useEffect(() => {
    function pageScroll() {
      setHasScroll(window.scrollY >= 30);
    }
    if (md) {
      window.addEventListener("scroll", pageScroll);
    }
    return () => {
      window.removeEventListener("scroll", pageScroll);
    };
  }, [md]);

  return (
    <div
      className={`relative hidden md:flex w-full ${
        hasScroll ? "bg-denim" : "bg-navbar"
      }`}
    >
      <nav className="container flex justify-between static py-4 top-0">
        <Link to="/">
          <img
            src={require(hasScroll
              ? "../../assets/logos/Logo-forager-foundation.png"
              : "../../assets/logos/Logo-white-forager-foundation.png")}
            alt="Logo Forager Foundation"
            className="w-[139px]"
          />
        </Link>
        <ul className="text-white flex items-center gap-10">
          <li className="dropdown dropdown-bottom dropdown-end">
            <label
              tabIndex={0}
              className="flex items-center gap-2 hover:text-primary with-transition"
              style={{ cursor: "pointer" }}
            >
              <span className="font-bold">About</span>
              <span className="mt-1">
                <i
                  className="iconify font-bold"
                  data-icon="material-symbols:arrow-forward-ios-rounded"
                  data-rotate="90deg"
                />
              </span>
            </label>
            <ul
              tabIndex={0}
              className="w-40 dropdown-content grid mt-2 bg-white text-denim rounded-lg rounded-tl-none"
            >
              <Link
                to="/our-story"
                className="px-2 py-3 hover:rounded-tr-lg hover:bg-primary hover:text-white hover:font-bold with-transition"
              >
                Our Story
              </Link>
              <Link
                to="/mission-and-values"
                className="px-2 py-3 hover:rounded-b-lg hover:bg-primary hover:text-white hover:font-bold with-transition"
              >
                Mission & Values
              </Link>
            </ul>
          </li>
          {/* <li className="dropdown dropdown-bottom dropdown-end">
            <label tabIndex={0} className="flex items-center gap-2 hover:text-primary">
              <span className="font-bold">heritage program</span>
              <span className="mt-1">
                <i
                  className="iconify font-bold"
                  data-icon="material-symbols:arrow-forward-ios-rounded"
                  data-rotate="90deg"
                />
              </span>
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content w-60 grid mt-2 bg-white text-denim rounded-lg rounded-tl-none"
            >
              <li className="px-2 py-3 hover:rounded-tr-lg hover:bg-primary hover:text-white">
                <Link to="/">Yale Historic Site</Link>
              </li>
              <li className="px-2 py-3 hover:rounded-b-lg hover:bg-primary hover:text-white">
                <Link to="/">Point Ellice House Museum</Link>
              </li>
            </ul>
          </li> */}

          <Link
            to="/heritage-sites"
            className="hover:text-primary font-bold with-transition"
          >
            Heritage Sites
          </Link>
          {/* <Link to="/our-team" className="hover:text-primary font-bold">
            Our Team
          </Link> */}
          <Link
            to="/contact"
            className="hover:text-primary font-bold with-transition"
          >
            Contact
          </Link>
        </ul>
      </nav>
    </div>
  );
};

export default AppHeader;
