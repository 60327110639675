import { useEffect, useState } from "react";

const UseBreakpoints = () => {
  const [breakpoints, setBreakpoints] = useState(getMatches());

  useEffect(() => {
    new ResizeObserver(() => {
      setBreakpoints(getMatches());
    }).observe(document.documentElement);
  }, []);

  function getMatches() {
    return {
      xs: window.matchMedia("(min-width: 0px)").matches,
      sm: window.matchMedia("(min-width: 640px)").matches,
      md: window.matchMedia("(min-width: 768px)").matches,
      lg: window.matchMedia("(min-width: 1024px)").matches,
      xl: window.matchMedia("(min-width: 1280px)").matches,
      "2xl": window.matchMedia("(min-width: 1536)").matches,
    };
  }

  return breakpoints;
};

export default UseBreakpoints;
