import React from "react";

import MainLayout from "../layouts/MainLayout";
import HomeHero from "../components/home/HomeHero";
import HomeAbout from "../components/home/HomeAbout";
import HomeHeritageProgram from "../components/home/HomeHeritageProgram";

const Home = () => {
  return (
    <MainLayout>
      <HomeHero />
      <HomeAbout />
      <HomeHeritageProgram />
    </MainLayout>
  );
};

export default Home;
