import React from "react";
import { Link } from "react-router-dom";

import Redes from "../../global/Redes";

const AppFooter = () => {
  return (
    <div className="container">
      <footer className="bg-denim pt-9">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-11 text-white">
          <div>
            <Link to="/">
              <img
                src={require("../../assets/logos/Logo-forager-foundation.png")}
                alt="Logo Forager Foundation"
                className="w-1/2"
              />
            </Link>
            <p className="mt-10 lg:pr-8 mb-10">
              Forager Foundation is a Canadian non-profit dedicated to
              preserving and promoting natural and cultural heritage through
              innovative community-driven projects in Canada and beyond.
            </p>
            <Redes styleIcons="w-full justify-start" />
          </div>
          <div className="lg:mx-auto">
            <ul className="flex flex-col gap-2 lg:gap-y-2">
              <p className="mb-6 text-2xl font-bold text-primary">Menu</p>
              <li>
                <p>About</p>
                <ul className="list-disc pl-6">
                  <li>
                    <Link
                      to="/our-story"
                      className="hover:text-primary with-transition"
                    >
                      Our Story
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/mission-and-values"
                      className="hover:text-primary with-transition"
                    >
                      Mission & Values
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link
                  to="/heritage-sites"
                  className="hover:text-primary with-transition"
                >
                  Heritage Sites
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="hover:text-primary with-transition"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex gap-y-2 flex-col">
            <p className="mb-6 text-2xl font-bold text-primary">Contact Us</p>
            <p>
              <span className="font-bold"> Headquarters: </span>
              Gibsons, Canada
            </p>
            {/* <p>
              <span className="font-bold"> Office: </span>
              Victoria, BC, Canada
            </p> */}
            <p>
              <span className="font-bold"> Office: </span>
              Yale, BC, Canada
            </p>
            <p>
              <span className="font-bold">Phone: </span>
              +1 (250) 800-1831
            </p>
            <p>
              <span className="font-bold block">General Enquiries:</span>
              <a
                href="mailto:info@foragerfoundation.com"
                target="_blank"
                rel="noreferrer"
                className="contact-links hover:text-primary with-transition"
              >
                info@foragerfoundation.com
              </a>
            </p>
          </div>
          <div className="col-span-full mt-5 mb-10">
            <span className="block w-full border-t-[1px] border-white mb-2 mx-auto" />
            <p className="lg:flex lg:justify-between px-3 lg:px-0 text-xs text-center">
              Copyright © {new Date().getFullYear()}, Forager Group Holdings
              Ltd.
              <span className="block">
                website created by
                <span className="font-semibold">
                  {" "}
                  forager | <span className="text-orange-digital">digital</span>
                </span>
              </span>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AppFooter;
