import React from "react";
import AppLeaves from "../../global/AppLeaves";
import AppCarousel from "../../global/AppCarousel";
import AppModal from "../../global/AppModal";
import hands from "../../assets/img/our-story/hands.svg";
import ForagersCompany from "./ForagersCompany";

const OurStoryForagerNetwork = () => {
  const foragerCompanies = [
    {
      name: "forager international",
      description:
        "The sole purpose of Forager International is to manage the operations of other Forager companies. It is through this business that the Forager Partnership Program is managed, which has now increased to include over 25% of the BC heritage sector, including several provincial heritage sites. Forager International also builds relationships with the larger heritage sector through strategic partnerships with organizations like the BC Museums Association and through its newly launched initiative, the Forager Labour Share Program, which connects heritage organizations with outsourced professionals offering cost-savings and flexible timelines without compromising quality.",
      backgroundImage: require("../../assets/img/our-story/bg-card-forager.jpg"),
      bgStyle: "rounded-tr-3xl rounded-bl-3xl",
      logo: (
        <img
          src={require("../../assets/logos/Logo-white-forager.png")}
          alt="logo forager"
          className="w-44"
        />
      ),
      link: "https://www.foragerinternational.com",
    },
    {
      name: "forager education",
      description:
        "With a specialty in developing curriculum-based programs and resources, Forager Education works with partners to create experiences that can be monetized and marketed to school groups. Through its team of teachers and developers, programs are adapted for in-person and online audiences ensuring maximum audience potential and revenue potential.",
      backgroundImage: require("../../assets/img/our-story/bg-card-forager-education.jpg"),
      bgStyle: "rounded-tr-3xl rounded-bl-3xl",
      logo: (
        <img
          src={require("../../assets/logos/Logo-white-forager-educacion.png")}
          alt="logo forager"
          className="w-44"
        />
      ),
      link: "https://www.foragereducation.com",
    },
    {
      name: "forager media group",
      description: (
        <article className="">
          <div className="col-span-3">
            <div className="mb-8">
              <img
                src={require("../../assets/logos/logo-forager-digital.png")}
                alt="logo Forager digital"
                className="w-48 mb-5"
              />
              <p>
                This business division has worked with partners in Canada, the
                USA, and Europe to design promotional materials, strategize the
                best marketing solutions, and develop new websites. It knows
                that working closely with partners and truly understanding their
                stories and their community is key to achieving the results they
                deserve.
              </p>
            </div>
            <div className="mb-8">
              <img
                src={require("../../assets/logos/logo-forager-virtual.png")}
                alt="logo Forager virtual"
                className="w-48 mb-5"
              />
              <p>
                This business division develops guided museum tours that
                integrate augmented reality, builds virtual scavenger hunts for
                schools to explore heritage sites, and creates virtual tours
                that allow people the opportunity to explore inaccessible
                places. In 2022/23 alone it has 75 productions on the go.
              </p>
            </div>
            <div className="mb-8">
              <img
                src={require("../../assets/logos/logo-forager-productions.png")}
                alt="logo Forager productions"
                className="w-48 mb-5"
              />
              <p>
                This business division leads in visual and audio storytelling.
                Whether it helps create a new podcast series or produces a
                promotional video or a documentary, it is dedicated to
                storytelling. Working with partners to showcase what they have
                to say with the technical skills to ensure it inspires
                audiences.
              </p>
            </div>
            <div className="mb-8">
              <img
                src={require("../../assets/logos/logo-forager-publishing.png")}
                alt="logo Forager publishing"
                className="w-48 mb-5"
              />
              <p>
                This business division brings ideas to life through words.
                Whether for marketing purposes or to create a new book, it is
                dedicated to crafting the perfect story. It has managed magazine
                publications and is currently working on a collection of digital
                workbooks for BC heritage organizations.
              </p>
            </div>
          </div>
        </article>
      ),
      backgroundImage: require("../../assets/img/our-story/bg-card-forager.jpg"),
      bgStyle: "rounded-tl-2xl rounded-br-2xl",
      logo: (
        <img
          src={require("../../assets/logos/Logo-white-forager-media-group.png")}
          alt="logo forager"
          className="w-44"
        />
      ),
      link: "https://www.foragermediagroup.com",
    },
    {
      name: "forager financial services",
      description:
        "Founded after consultation with partners around BC, Forager Financial Services specializes in offering high-quality professional accounting services to heritage organizations. Its team is new to working together, but its experience in the accounting world is over nine years. From bookkeeping to tax preparation to financial statement preparation, its team of outsourced accountants assists in all financial needs, including researching and applying for external funding.",
      backgroundImage: require("../../assets/img/our-story/bg-card-forager-financial.jpg"),
      bgStyle: "rounded-tl-2xl rounded-br-2xl",
      logo: (
        <img
          src={require("../../assets/logos/Logo-white-forager-financial.png")}
          alt="logo forager"
          className="my-auto w-44"
        />
      ),
      link: "https://www.foragerfinancial.com",
    },
  ];

  return (
    <div className="bg-white pb-12 lg:pb-24">
      <section className="pt-12 md:pt-0 container">
        <div className="w-24 mx-auto">
          <AppLeaves />
        </div>
        <article className="max-w-3xl mx-auto">
          <h3 className="my-8 font-title text-primary text-center">
            the larger forager network
          </h3>
          <p className="text-center">
            By having the financial and operational support of the larger
            Forager Network, Forager Foundation is part of an internationally
            recognized model that brings together the strengths of both
            for-profit and nonprofit organizations. The businesses that are
            ensuring Forager Foundation can continue its mandate are all
            specialists in different sides of heritage.
          </p>
        </article>
      </section>
      <div className="md:hidden my-12 lg:mt-8 lg:mb-16">
        <AppCarousel width="w-fit mx-auto">
          <div className="hidden lg:block" />
          {foragerCompanies.map((company, i) => (
            <AppModal
              key={i}
              activator={
                <div
                  className={`mr-4 w-[276px] h-[199px] mb-4 bg-cover bg-no-repeat bg-center ${company.bgStyle}`}
                  style={{
                    backgroundImage: `url(${company.backgroundImage})`,
                  }}
                >
                  <div className="px-12 h-full grid place-items-center">
                    {company.logo}
                  </div>
                </div>
              }
              boxClass="w-full max-w-4xl max-h-screen md:max-h-[80%]"
            >
              <ForagersCompany company={company} />
            </AppModal>
          ))}
        </AppCarousel>
      </div>

      <div className="hidden md:grid my-12 lg:mt-8 lg:mb-16 grid-cols-4 gap-4 2xl:gap-6 container">
        {foragerCompanies.map((company, i) => (
          <AppModal
            key={i}
            activator={
              <div
                className={`h-[199px] mb-4 bg-cover bg-no-repeat bg-center hover:border-4 hover:border-primary ${company.bgStyle} with-transition cursor-pointer`}
                style={{
                  backgroundImage: `url(${company.backgroundImage})`,
                }}
              >
                <div className="px-12 h-full grid place-items-center overflow-hidden">
                  {company.logo}
                </div>
              </div>
            }
            boxClass="w-full max-w-6xl max-h-screen md:max-h-[90%]"
          >
            <ForagersCompany company={company} />
          </AppModal>
        ))}
      </div>

      <div className="container">
        <article className="overflow-hidden lg:flex lg:justify-center bg-denim rounded-tl-3xl rounded-br-3xl text-white shadow-lg">
          <img
            src={hands}
            alt="forager gives back"
            className="w-full md:w-[500px] mb-auto object-bottom"
          />
          <div className="px-4 lg:px-0 py-10 lg:pr-11 lg:-ml-16">
            <h5 className="mb-7 font-title">forager gives back</h5>
            <p>
              The benefit of having the support of the Forager International
              Network is that a portion of all revenue for Forager projects
              around the world is donated to Forager Foundation to provide even
              further financial support for programs that the Foundation works
              on. Funding is never enough from one single source, so by
              receiving a consistent revenue source from Forager International
              business operations, Forager Foundation can offset the funding
              from government and private and corporate donations.
            </p>
          </div>
        </article>
      </div>
    </div>
  );
};
export default OurStoryForagerNetwork;
