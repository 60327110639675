import React from "react";
import { Link } from "react-router-dom";
import useBreakpoints from "../hooks/UseBreakpoints";

import MainLayout from "../layouts/MainLayout";

const YaleHistoricSite = () => {
  const { md } = useBreakpoints();
  return (
    <MainLayout>
      <div
        className="app-hero gradient-y md:gradient-tl"
        style={{
          "--bg-image": `url(${require(md
            ? "../assets/img/yale/bg-yale-desktop.jpg"
            : "../assets/img/yale/bg-yale.jpg")})`,
        }}
      >
        <div className="app-hero__container pt-1">
          <h1 className="app-hero__title lg:max-w-2xl lg:leading-[70px] pb-5">
            <span className="app-hero__title--small">
              the first stop on <span className="uppercase">bc</span>
              <span className="lowercase">’s</span> gold rush
            </span>
            yale historic site
          </h1>
        </div>
      </div>
      <div className="container lg:px-[252px]">
        <div className="md:text-center">
          <h2 className="font-title text-primary mb-8">
            from boom town to quaint village
          </h2>
          <p className="text-white mb-16 lg:mb-20">
            Nestled in the heart of British Columbia, Yale provides the perfect
            balance of adventure and tranquillity. Once a thriving hub of
            activity during the gold rush, Yale has transformed into a
            picturesque village, home to the captivating Yale Historic Site. The
            Site allows visitors to delve into the excitement and daring of the
            gold rush while also taking in a quaint village's natural beauty and
            peacefulness. With so much to see and do, from the Beth Clare Garden
            to the Ward Tea House, Yale Historic Site is a must-see destination
            for anyone interested in experiencing the magic of British
            Columbia's Fraser Valley.
          </p>
        </div>
      </div>
      <div className="bg-white mt-6">
        <div className="grid lg:grid-cols-2 md:container lg:gap-32 lg:pt-24">
          <div className="container lg:px-0 mb-12 lg:mb-0 lg:order-2">
            <h2 className="font-title text-primary pt-12 pb-8 lg:pt-0">
              the st. john the divine church
            </h2>
            <div className="grid gap-5">
              <p>
                St. John the Divine church has stood for over a century and a
                half, bearing witness to the changing times and the story of a
                community that has grown around it. Initially built in 1863, the
                church was intended to be a beacon of civility and order amidst
                the rugged landscape of the miners' town it overlooked. Today,
                the church is a testament to the perseverance of its people,
                having undergone a significant restoration in 1953 and another
                smaller one in 2001. Yet, despite the passage of time, the
                original walls, rafters, and handmade nails have stood the test
                of time, still clearly visible to all who enter.
              </p>
              <p>
                Step inside the church and a collection of original altarpieces
                greet you from the 1860s and the "Enduring Threads" exhibit, a
                stunning collection of antique linens hand-stitched by the
                students of All Hallows in the West girl’s school.
              </p>
              <p>
                While the church served as a place of worship from its
                completion on April 19, 1863, until it officially closed in
                1976, visitors are welcome to experience the church's historical
                significance and architectural beauty whenever they visit. For
                those looking for a unique and memorable venue, St. John the
                Divine church is also available for wedding and event rentals.
                Whether you're a history buff or simply looking for a stunning
                backdrop for your special day, St John the Divine church is a
                true gem waiting to be discovered.
              </p>
            </div>
          </div>
          <div
            className="h-96 lg:h-full w-full bg-cover bg-no-repeat bg-center rounded-tr-3xl rounded-bl-3xl"
            style={{
              backgroundImage: `url(${require("../assets/img/yale/the-st.-john-the-divine-church.jpg")})`,
            }}
          />
        </div>
        <div className="grid lg:grid-cols-2 md:container lg:gap-32 lg:py-24">
          <div className="container lg:px-0 mb-12 lg:mb-0">
            <h2 className="font-title text-primary pt-12 pb-8 lg:pt-0">
              the creighton house
            </h2>
            <div className="grid gap-5">
              <p>
                Step into the Creighton House, a heritage building that dates
                back to the 1870s, and you'll be transported to a different era.
                This remarkable site is home to Yale's museum, gift shop,
                archives, and the Beth Clare Garden, where history comes to
                life. As you walk through the door, the scent of history is in
                the air. The Creighton House is one of the main exhibition
                spaces at the Yale Historic Site and one of Forager Foundation’s
                most significant undertakings after taking over the Site. A
                newly imagined exhibition space will occupy this house and be
                one of the many additions made to draw in more visitors to the
                Site. Outside, the Living History "Tent City" takes you back to
                the gold rush of 1858, where you can pan for gold and experience
                what life was like.
              </p>
              <p>
                Come and visit the Creighton House, where the past comes to
                life, and the stories of Yale are waiting to be discovered.
              </p>
            </div>
          </div>
          <div
            className="h-80 lg:h-full w-full bg-cover bg-no-repeat bg-center rounded-tl-3xl rounded-br-3xl"
            style={{
              backgroundImage: `url(${require("../assets/img/yale/the-creighton-house.jpg")})`,
            }}
          />
        </div>
        <div className="container max-w-6xl md:text-center mx-auto py-12 md:pt-0 md:pb-24 text-denim">
          <img
            src={require("../assets/logos/logo-short-forager-foundation.png")}
            alt="Logo short forager foundation"
            className="w-28 lg:w-20 md:mx-auto mb-5"
          />
          <h2 className="mb-8 font-title text-primary lg:text-5xl font-bold">
            forager at yale historic site
          </h2>
          <p className="mb-5">
            After a successful application to an open RFP call from the Province
            of British Columbia, Forager Foundation was granted a multiyear
            contract to oversee the management and innovation of the Yale
            Historic Site. Our collaborative approach and recognized innovation
            experience led us to a successful application. Over the coming
            years, we are ready to promote this Site far and wide.
          </p>
          <p className="mb-5">
            Our plan for the Site, first and foremost, is to continue on the
            legacy of the dedicated volunteers and managers that came before us.
            The Site is here today because of their hard work, and Forager
            Foundation is focused on continuing that work while also bringing
            Forager’s expertise in programming, curation, and tech innovation.
            The Yale Historic Site has rich Indigenous, settler, and Chinese
            Canadian history. Our goals at the Site are to work with community
            partners and utilize the strong heritage partnerships that Forager
            International has developed to create new interactive exhibits and
            innovative resources that engage with in-person visitors and those
            who want to explore the site from other parts of the world.
          </p>
          <p className="mb-5">
            We are also putting together a Community Input Council of locals to
            ensure that the community values our work and maintains the heritage
            value that the community sees from this site.
          </p>
          <p className="mb-8">
            If you are a Yale local and are interested in being part of the
            Community Input Council, contact us to put your name forward.
          </p>
          <div className="mx-auto w-full">
            <Link
              to="/contact"
              className="app-primary-button py-4 px-9 mx-auto w-full md:w-fit with-transition"
            >
              lend your voice
            </Link>
          </div>
        </div>

        <div
          className="app-hero gradient-b"
          style={{
            "--bg-image": `url(${require(md
              ? "../assets/img/yale/bg-visit-yale-desktop.jpg"
              : "../assets/img/yale/bg-visit-yale-mobile.jpg")})`,
          }}
        >
          <div className="app-hero__container pt-24 pb-32 md:py-32">
            <h1 className="app-hero__title lg:max-w-2xl lg:leading-[70px] text-center text-primary md:mx-auto">
              visit yale historic site
              <span className="app-hero__title--small text-white text-base font-bold text-center lg:mt-3">
                address:
                <span className="text-white text-base font-normal pr-5">
                  {" "}
                  31187 Douglas St Yale, BC V0K 2S0, Canada
                </span>
                <p>
                  operating hours (May 1 to Sep 30):
                  <span className="text-white text-base font-normal pr-5">
                    {" "}
                    Wed-Sun 10:00-16:00
                  </span>
                </p>
              </span>
            </h1>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default YaleHistoricSite;
